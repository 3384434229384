<template>
  <div class="indexStyle">

        <!-- PC端轮播图模块 -->
        <div class="slidShow">
            <el-carousel :height="realRatio_height+'px'">
                <el-carousel-item v-for="(item,index) in slidShowList" :key="index">
                    <img :src="item" mode="aspectFill"  @click="jumpUrl(index)">
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 移动端轮播图 -->
        <div class="mobileSlidShow" :style="`height:${realRatio_height}px`">
            <el-carousel :height="realRatio_height+'px'">
                <el-carousel-item v-for="(item,index) in mobile_slidShowList" :key="index">
                    <img :src="item">
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- ipad移动端轮播图 -->
        <!-- <div class="ipadMobileSlidShow">
            <el-carousel height="300px">
                <el-carousel-item v-for="(item,index) in slidShowList" :key="index">
                    <img :src="item">
                </el-carousel-item>
            </el-carousel>
        </div> -->
        <!-- 品牌 -->
        <!-- <div class="brand">
            <div class="h1">因为专注，所以专业</div>
            <div class="p">名称品牌设计，自成立以来致力于为注重品牌策略和品牌形象的高要求客户提供品牌战略营销咨询、品牌策划设<br/>计、产品策划包装设计品牌传播推广等服务； <br/>我们不断学习、成长，不断积累项目操作经验，努力成为中国设计界不可或缺的力量。</div>
            <div class="icon">
                <div class="icon_l">
                    <img src="@/assets/image/indexicon1.png" alt="">
                    <p>技术实现</p>
                </div>
                <div class="icon_r">
                    <img src="@/assets/image/indexicon2.png" alt="">
                    <p>解决方案</p>
                </div>
            </div>
        </div> -->
        <!-- 定制开发 -->
        <div class="develop">
                <ul>
                    <li v-for="(item,index) in developList" :key="index">
                        <img :src="item.img" alt="">
                        <p  name="developText">{{item.title}}</p>
                        <p  name="developText">{{item.content}}</p>
                    </li>
                </ul>
        </div>
        <!-- 服务领域 -->
        <div class="territory" :style="`height:${territory_height}px`">
            <div class="h2" v-show="territoryShow">服务领域</div>
            <div class="content" v-show="territoryShow">峰盛科技拥有丰富的开发经验，累计几十种解决方案可供参考，涉及各行业及各领域方案，
包含模板系统、定制开发等，满足您的一切需求</div>
            <ul  v-show="territoryShow">
                <li @click="jump(item.url)" v-for="(item,index) in territoryList" :key="index"
                :style="`background: url('${item.img}') no-repeat center;background-size:${territoryImg_width}px ${territoryImg_height}px;width:${territoryImg_width}px;height:${territoryImg_height}px` ">
                    <div class="title" :style="{'font-size': (windowWidth >1024 ? (title_size + 'px') : '12px' )}">
                        <p>{{item.title}}</p>
                        <p>解决方案</p>
                        <p>——</p>
                    </div>
                    <div class="content">
                        <p :style="{'font-size': (windowWidth >1024 ? (content_size + 'px') : '12px' )}">{{item.content1}}</p>
                        <p :style="{'font-size': (windowWidth >1024 ? (content_p_size + 'px') : '10px' )}">{{item.content2}}</p>
                        <p :style="{'font-size': (windowWidth >1024 ? (content_p_size + 'px') : '10px' )}">查看详情 ></p>
                    </div>
                </li>
                <div class="list" :style="`width:${territoryImg_width}px;height:${territoryImg_height}px`"  v-for="item in (rowNum - territoryList.length % rowNum)"><div v-if="territoryList.length % rowNum > 0"></div></div>
            </ul>
        </div>
        <!-- 开发案例 -->
        <correlationCase :caseShow="caseShow"  :type="1" @caseSwitch="caseSwitch" :caseList="caseList" />
        <!-- 合作伙伴模块 -->
        <div class="partner">
            <div class="h2" v-show="partnerShow">合作伙伴</div>
            <div class="partner_logo">
                <ul v-show="partnerShow">
                    <li v-for="(item,index) in partnerLogoList" :key="index">
                        <img :src="item">
                    </li>
                </ul>
            </div>
        </div>
            <el-dialog
            :visible.sync="advisoryShow"
            style="padding:0!important"
            width="30%"
            top="30vh"
            center>
            <div>   
                <span>欢迎您来到峰盛科技，有什么可以帮到您？</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="advisoryShow = false">稍后再说</el-button>
                <el-button type="primary" @click="advisoryShow = false">现在咨询</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import correlationCase from '@/components/correlationCase'
export default {
    data() {
        return {
            slidShowList:[
                require("@/assets/image/slideshow4.png"),
                require("@/assets/image/slideshow2.png"),
               
                require("@/assets/image/slideshow1.png")
            ],
            mobile_slidShowList:[
                require("@/assets/image/mobile_slideshow5.png"),
                require("@/assets/image/mobile_slideshow3.png"),
                
                require("@/assets/image/mobile_slideshow1.png")
            ],
            partnerLogoList: [
                require('@/assets/image/partner1.png'),
                require('@/assets/image/partner2.png'),
                require('@/assets/image/partner3.png'),
                require('@/assets/image/partner4.png'),
                require('@/assets/image/partner5.png'),
                require('@/assets/image/partner6.png'),
                require('@/assets/image/partner7.png'),
                require('@/assets/image/partner8.png'),
                require('@/assets/image/partner9.png'),
                require('@/assets/image/partner10.png'),
                require('@/assets/image/partner11.png'),
                require('@/assets/image/partner12.png'),
            ],
            developList:[
                {
                    img:require('@/assets/image/developType1.png'),
                    title:'安卓APP定制开发',
                    content:'致力于Android手机App开发，更兼容，更便捷。'
                },
                {
                    img:require('@/assets/image/developType2.png'),
                    title:'苹果APP定制开发',
                    content:'高端型IOS App外包，全程一站式的App软件开发。'
                },
                {
                    img:require('@/assets/image/developType3.png'),
                    title:'HTML5定制开发',
                    content:'跨平台混合式开发，卓越的用户体验，广州手机软件开发。'
                },
                {
                    img:require('@/assets/image/developType4.png'),
                    title:'微信定制开发',
                    content:'微信小程序开发，微信公众号二次开发，微商城定制开发'
                },
            ],
            territoryList:[
                {
                    img:require('@/assets/image/territory1.png'),
                    title:'企业建站系统',
                    url:'enterprise',
                    content1:'企业建站开发服务',
                    content2:'为各类型企业定制开发多功能显示官网'
                },
                {
                    img:require('@/assets/image/territory2.png'),
                    title:'数字化系统定制',
                    url:'customization',
                    content1:'全能定制开发服务',
                    content2:'只有你想不到没有我们做不到定制开发'
                },
                {
                    img:require('@/assets/image/territory3.png'),
                    title:'数字电商系统',
                    url:'onlineStore',
                    content1:'多场景在线商城开发服务',
                    content2:'为各种类型商家提供线上购物商城定制系统'
                },
                {
                    img:require('@/assets/image/territory4.png'),
                    title:'通用小程序模板',
                    url:'applet',
                    content1:'通用小程序模板打包服务',
                    content2:'提供各式各样模板小程序快速搭建三天上线'
                },
                
            ],
            caseList:[],
            //营销策划
            marketingList:[
                {
                    img:require('@/assets/image/case/1.png'),
                    title:'营销策划系统',
                    content1:'秒杀活动',
                    content2:'宣传店铺，限时秒杀购券引流到店'
                },
                {
                    img:require('@/assets/image/case/2.png'),
                    title:'营销策划系统',
                    content1:'答题活动',
                    content2:'答题互动宣传活动，扩大品牌传播'
                },
                {
                    img:require('@/assets/image/case/3.png'),
                    title:'营销策划系统',
                    content1:'抽奖活动',
                    content2:'进行宣传促销，抽奖派券赢大奖'
                },
                {
                    img:require('@/assets/image/case/4.png'),
                    title:'营销策划系统',
                    content1:'积分活动',
                    content2:'日常打卡活动，有效增加用户粘性'
                },
            ],
            //旅游
            tourList:[
                {
                    img:require('@/assets/image/case/5.png'),
                    title:'旅游系统',
                    content1:'行走二十岁',
                    content2:'为不同业态的旅游企业提供全方位的方案'
                },
                {
                    img:require('@/assets/image/case/6.png'),
                    title:'旅游系统',
                    content1:'启航旅游',
                    content2:'全媒体接入扩展性，满足客户多样化需求'
                },
                // {
                //     img:require('@/assets/image/case/7.png'),
                //     title:'旅游系统',
                //     content1:'企业建站开发服务',
                //     content2:'为各类型企业定制开发多功能显示官网'
                // },
                {
                    img:require('@/assets/image/case/8.png'),
                    title:'旅游系统',
                    content1:'乐翻天旅游',
                    content2:'提供服务旅游网管管理系统，三网合一'
                },
            ],
            //电商
            storeList:[
                {
                    img:require('@/assets/image/case/9.png'),
                    title:'电商系统',
                    content1:'热风微商城',
                    content2:'专注为企业搭建优质的电商平台建设'
                },
                {
                    img:require('@/assets/image/case/10.png'),
                    title:'电商系统',
                    content1:'凯德星商城',
                    content2:'多种运营模式组合，构建私域运营体系'
                },
                {
                    img:require('@/assets/image/case/11.png'),
                    title:'电商系统',
                    content1:'尚璇电商',
                    content2:'助力为企业快速获取流量，实现销量突破'
                },
                {
                    img:require('@/assets/image/case/12.png'),
                    title:'电商系统',
                    content1:'亿人电商',
                    content2:'帮助企业低成本快速构建在线商城'
                },
            ],
            //美容
            hotelList:[
                {
                    img:require('@/assets/image/case/13.png'),
                    title:'美容系统',
                    content1:'美莱医疗美容',
                    content2:'美容互联网解决方案，构建线上预约的新消费模式'
                },
                {
                    img:require('@/assets/image/case/14.png'),
                    title:'美容系统',
                    content1:'亿念美美容',
                    content2:'专注美业saas系统，线上线下营销会员拓客溜客'
                },
                {
                    img:require('@/assets/image/case/15.png'),
                    title:'美容系统',
                    content1:'毛大夫医疗整形',
                    content2:'美容系统数据哭稳定、安全、集成数据库'
                },
                {
                    img:require('@/assets/image/case/16.png'),
                    title:'美容系统',
                    content1:'Dr.Arrivo美容液',
                    content2:'店务+报表+会员一体化，提供贴合美容行业方案'
                },
            ],
            //酒店管理
            hairdressing:[
                {
                    img:require('@/assets/image/case/17.png'),
                    title:'酒店管理系统',
                    content1:'花筑旅行',
                    content2:'酒店管理系统大平台，连锁酒店，中小酒店，轻松满足'
                },
                {
                    img:require('@/assets/image/case/18.png'),
                    title:'酒店管理系统',
                    content1:'艺龙会酒店',
                    content2:'信息化、网络化、规范化，高效性执行酒店信息管理'
                },
                {
                    img:require('@/assets/image/case/19.png'),
                    title:'酒店管理系统',
                    content1:'万爱情侣酒店',
                    content2:'以物联网、云计算为基础建设，提供酒店智能化管理'
                },
                {
                    img:require('@/assets/image/case/20.png'),
                    title:'酒店管理系统',
                    content1:'飞火电竞',
                    content2:'为管理层提供有效数据依据，指导决策的酒店信息化'
                },
            ],
            advisoryShow:false,
            windowWidth: document.documentElement.clientWidth, // 实时屏幕宽度
            //根据设计图比例设计缩放的图片高度
            //轮播图适配
            realRatio_height: 
             document.documentElement.clientWidth * 0.35,
            //服务领域适配
            territoryImg_height: 
              document.documentElement.clientWidth >750? document.documentElement.clientWidth * 0.27 : document.documentElement.clientWidth * 0.472,

            territoryImg_width:  
             document.documentElement.clientWidth >750? document.documentElement.clientWidth * 0.31 : document.documentElement.clientWidth * 0.50,

            territory_height:  
              document.documentElement.clientWidth >1024? document.documentElement.clientWidth * 0.82 :  document.documentElement.clientWidth >750? document.documentElement.clientWidth * 0.72 : document.documentElement.clientWidth * 1.3 ,
            title_size: document.documentElement.clientWidth * 0.019 ,
            content_size: document.documentElement.clientWidth * 0.014 ,
            content_p_size: document.documentElement.clientWidth * 0.011 ,
            territoryShow:false,
            caseShow:false,
            partnerShow:false,
            rowNum:6,

        }
    },
    components: {
        correlationCase
    },
    mounted(){
        this.caseList = this.marketingList
        // <!--把window.onresize事件挂在到mounted函数上-->
        // 监听窗口的浏览器的放大缩小
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.windowWidth = window.fullWidth // 宽
                if(this.windowWidth > 1024){
                    this.realRatio_height = this.windowWidth * 0.35
                    this.territoryImg_height = this.windowWidth * 0.27
                    this.territoryImg_width = this.windowWidth * 0.31
                    this.territory_height = this.windowWidth * 0.72
                    this.title_size = this.windowWidth * 0.019
                    this.content_size = this.windowWidth * 0.014
                    this.content_p_size = this.windowWidth * 0.011
                }
                else if(this.windowWidth <= 750){
                    this.territoryImg_width = this.windowWidth * 0.50
                    this.territoryImg_height = this.windowWidth * 0.472
                    this.territory_height = this.windowWidth * 1.3
                }
                
                     
            })()
        }
        if(this.windowWidth <= 1024){
            this.territoryShow=true
            this.caseShow = true
            this.partnerShow = true
        } 
         window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
    },
    methods:{
        jump(url){
            this.$router.push({name:url})
        },
        caseSwitch(index){
            console.log(index);
            if(index === 0) this.caseList = this.marketingList
            if(index === 1) this.caseList = this.tourList
            if(index === 2) this.caseList = this.storeList
            if(index === 3) this.caseList = this.hairdressing
            if(index === 4) this.caseList = this.hotelList
        },
        // 滚动监听 · 动画播放
        handleScroll(){ 
            if(this.windowWidth <= 1024) return
                // 页面滚动距顶部距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || 
                    document.body.scrollTop
            //动画播放设置
            //定制开发
            // let develop = document.getElementsByClassName('develop')
            // let developUl = develop[0].getElementsByTagName('ul')
            // let developText = document.getElementsByName('developText')
            //服务领域
            let territory = document.getElementsByClassName('territory')
            let territory_h2 = territory[0].getElementsByClassName('h2')
            let territory_content = territory[0].getElementsByClassName('content')
            let territoryLi = territory[0].getElementsByTagName('li')
            //开发案例
            let Case = document.getElementsByClassName('case')
            let Case_h3 = Case[0].getElementsByClassName('h3')
            let intelligen_ul = Case[0].getElementsByClassName('intelligen_ul')
            let Case_swiper = Case[0].getElementsByClassName('case-swiper-container')
            //合作伙伴
            let partner = document.getElementsByClassName('partner')
            let partner_h2 = partner[0].getElementsByClassName('h2')
            let partnerLi = partner[0].getElementsByTagName('li')

            // if(scrollTop > (develop[0].getBoundingClientRect().top + scrollTop) /2){
            //     this.developShow = true
            //     developUl[0].classList.add('animated','fadeInUpBig')
            //     developText.forEach(i=>{
            //         i.classList.add('animated','fadeIn')
            //     })   
            // } 
            if(scrollTop > (territory[0].getBoundingClientRect().top + scrollTop) - territory[0].offsetHeight){
                this.territoryShow = true
                territory_h2[0].classList.add('animated','fadeIn')
                territory_content[0].classList.add('animated','fadeIn')
                territoryLi.forEach((item,index)=>{
                    if((index + 1) % 2 == 0){
                        item.classList.add('animated','fadeInRight')
                    }else{
                        item.classList.add('animated','fadeInLeft')
                    }
                })
            }
            if(scrollTop > (Case[0].getBoundingClientRect().top + scrollTop) - Case[0].offsetHeight){
                this.caseShow = true
                Case_h3[0].classList.add('animated','fadeIn')
                intelligen_ul[0].classList.add('animated','fadeIn')
                Case_swiper[0].classList.add('animated','fadeInUp')
            }
            if(scrollTop > (partner[0].getBoundingClientRect().top + scrollTop) - partner[0].offsetHeight  + 100){
                console.log(1);
                this.partnerShow = true
                partner_h2[0].classList.add('animated','fadeIn')
                intelligen_ul[0].classList.add('animated','fadeIn')
                partnerLi.forEach(i=>{
                    i.classList.add('animated','zoomIn')
                }) 
            }
        },
        jumpUrl(index){
            console.log(index);
            if(index == 0) window.open('https://travel.fengshenginfo.com/')
        },
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    },
}
</script>

<style lang="less" scoped>

.indexStyle {
    width: 100%;
    // 轮播图模块
    .slidShow {
        background-color: #323232;
        img {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    // 品牌设计
    .brand {
        position:relative;
        height: 590px;
        text-align: center;
        background-color: #323232;

        .h1 {
            color:#fff;
            font-size: 50px;
            font-weight: bold;
            font-family: Source Han Sans CN;
            padding:110px 0 46px 0;
        }
        .p {
            width: 882px;
            height: 81px;
            font-size: 18px;
            color: #FFF;
            margin-top: 20px;
            position: absolute;
            top:191px;
            left: 50%;
            transform: translateX(-50%);
        }
        .icon {
            display: flex;
            justify-content:center;
            margin-top: 120px;
            p {
                    font-size: 24px;
                    color: #fff;
                    font-family: Source Han Sans CN;
                }
            .icon_l {
                margin-right: 205px;
                img {
                    width: 51px;
                    height: 64px;
                }
                
            }
            .icon_r {
                img {
                    width: 55px;
                    height: 56px;
                }
                
            }
        }
    }
    //定制开发
    .develop{
        height: 293px;
        background: url('~@/assets/image/rectangle.png') no-repeat;
        display: flex;
        align-items: center;
        ul{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-wrap: wrap;
            width: 1220px;
            height: 100%;
            margin: 0 auto;
            li{
                width: 230px;
                text-align: center;
                p:nth-child(2){
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin: 25px 0 15px 0;
                }
                p:nth-child(3){
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 300;
                    color: #666666;
                    line-height: 25px;
                }
            }
        
         }
    }
    //服务领域
    .territory{
        height: 1497px;
        .h2{
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            line-height: 81px;
            padding: 99px 0 40px;
        }
        .content{
            width: 707px;
            margin: 0 auto;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #666666;
        }
        ul{
            padding-top: 50px;
            width: 1220px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            margin: 0 auto;
            li{
                width: 600px;
                height: 565px;
                position: relative;
                cursor: pointer;
                .title{
                    position: absolute;
                    top: 5%;
                    left: 4%;
                    font-size: 34px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 50px;
                    text-align: left;
                }
                .content{
                    width: 100%;
                    position: absolute;
                    bottom: 5%;
                    left: 0;
                    p:nth-child(1){
                        font-size: 28px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #333333;
                        
                    }
                    p:nth-child(2){
                        font-size: 20px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #666666;
                    }
                    p:nth-child(3){
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #078AFA;
                        margin-top: 10px;
                    }
                }
            }
            .list{
                content: '';
                 width: 600px;
                height: 565px;
                border: 1px solid transparent;
                padding: 5px;
                overflow: hidden;
            }
        }
    }
    // 合作伙伴模块
    .partner {
        height: 735px;
        .h2 {
            font-size: 36px;
            font-weight: bold;
            color: #333333;
            line-height: 81px;
            padding: 99px 0 40px;
        }
        .partner_logo {
            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                width: 1080px;
                margin: 0 auto;
                height: 420px;
                li {
                    width: 250px;
                    background-color: #fff;
                    margin: 10px 10px;
                    // box-shadow: 0px 0px 2px #666;
                    img {
                        width: 80%;
                        height: 80%;
                        transition: all 0.6s;    //设置动画执行的时间为0.6s
                        &:hover {
                            transform: scale(1.2);     //设置图片按照比例放大1.2倍
                        }
                    }
                }
            }
        }
    }
    /deep/.el-dialog{
        div{
            text-align: center;
        }
    }

    .fadeIn{
        // animation-duration: 5s;         //动画持续时间
        animation-delay: .5s !important;              //动画延迟时间
        // animation-iteration-count: 2 !important;
    }
    .zoomIn,.fadeInRight,.fadeInLeft,.fadeInUp{
        // animation-duration: 5s;         //动画持续时间
        animation-delay: 1s !important;              //动画延迟时间
        // animation-iteration-count: 2 !important;
    }
    
}



@media screen and (max-width: 1024px) {
    .indexStyle {
        margin-top: 0px;
        .slidShow {
            // height: 180px;
            display: none;
        }
        .mobileSlidShow {
            background-color: #323232;
            height: 180px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
        // .ipadMobileSlidShow {
        //     // display: none 
        //     background-color: #323232;
        //     img {
        //         width: 100%;
        //         height: 100%;
        //     }
        // }
        .brand {
            height: 285px;
            .h1 {
                font-size: 12px;
                padding: 39px 0 10px 0;
            }
            .p {
                width: 335.5px;
                height: 91.5px;
                font-size: 12px;
                color: #FFF;
                position: absolute;
                top: 60px;
                left: 50%;
                transform: translateX(-50%);
            }
            .icon {
                p {
                    font-size: 14px;
                }
                .icon_l {
                    margin-right: 103px;
                    img {
                        width: 25.5px;
                        height: 32px;
                    }
                }
                .icon_r {
                    img {
                        width: 25.5px;
                        height: 32px;
                    }
                }
            }
        }
        .develop{
            height: auto;
            width: 100%;
            ul{
                align-items: unset  ;
                width: 100%;
                padding-bottom: 25px;
                li{
                    margin-top: 30px;
                    width: 160px;
                    img{
                        height: 26px;
                    }
                    p:nth-child(2){
                        font-size: 13px;
                        margin: 10px 0;
                    }
                    p:nth-child(3){
                        font-size: 10px;
                        transform: scale(0.9);
                        line-height: 14px;
                    }
                }
            }
        }
        .territory{
            height: 500px;
            .h2{
                font-size: 19px;
                padding: 30px 0 15px 0;
                line-height: 0;
            }
            .content{
                width: 340px;
                font-size: 11px;
                line-height: 15px;
            }
            ul{
                width: 100%;
                padding-top: 18px;
                li{
                    width: 173px;
                    height: 185px;
                    background-size:  173px 185px;
                    .title{
                        font-size: 12px;
                        line-height: 17px;
                        top: 23px;
                        left: 5px;
                    }
                    .content{
                         bottom: 5px;
                        p:nth-child(1){
                            font-size: 14px;
                        }
                        p:nth-child(2){
                            font-size: 10px;
                            transform: scale(0.9);
                            width: 156px;
                            margin: 0 auto;
                        }
                        p:nth-child(3){
                            display: none;
                        }
                    }
                }
            }
        }
        .partner {
            // display: none;
            height: 380px;
            .h2 {
                font-size: 19px;
                padding: 0;
            }
            .partner_logo {
                position: relative;
                ul {
                    position: absolute;
                    top: 0;
                    left: 52%;
                    transform: translateX(-50%);
                    width: 87%;
                    height: 290px; 
                    // background-color: red;
                    li {
                        width: 25%;
                        height: 15%;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
          /deep/ .el-dialog{
                width: 280px !important;
                height: 180px !important;
                .el-dialog__footer{
                    padding: 0;
                }
                span{
                    font-size: 12px
                }
          }
    }
    
}

// @media screen  and (min-weight: 320px) and (max-width: 414px) {
//     .mobileSlidShow {
//             background-color: #323232;
//             img {
//                 width: 100%;
//                 height: 100%;
//             }
//         }
//          .ipadMobileSlidShow {
//             display: none 
//         }
// }


@media screen and (width: 768px) {
    // .ipadMobileSlidShow {
    //     display: block !important;
    //     img {
    //         width: 100% !important;
    //         height: 100% !important;
    //     }
    // }
    .partner {
        height: 550px !important;
        .partner_logo {
            ul {
                left: 50% !important;
                height: 350px !important;
                li {
                    width: 30% !important;
                    height: 25% !important;
                }
            }
        }
    }
    
}


@media screen and (min-width: 1025px) {
    .mobileSlidShow {
        display: none;
    }
    .indexStyle{
        margin-top: 90px;
    }
}
</style>